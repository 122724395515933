html, body {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
  background-color: #333333;
  box-sizing: border-box;
}

button {
	cursor: pointer;
}
