nav {
  background: #333333;
  text-align: center;
  font-weight: 900;
}

nav a {
  color: #ededed;
  text-decoration: none;
  transition: color 0.2s ease;
}

nav a:hover {
  text-decoration: none;
  color: #0D52C1;
}

.links {
  text-align: center;
}