article {
    text-align: right;
}

.legend {
    display: flex;
    justify-content: center;
}

Button {
    text-align: center;
}
