.footer{
    background: #333333;
    text-align: center;
    padding: 1rem 0;
    font-weight: 900;
}

.footer a{
    text-decoration: none;
    color: #ededed;
    transition: color 0.2s ease;
}

.footer a:hover {
    color: #0D52C1;
}

.footer__link{
    font-size: 1.25rem;
}

.social-list{
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 2em 0 0;
    padding: 0;
}

.social-list__item{
    margin: 0 0.5em;
}

.social-list__link{
    padding: 0.5em;
}
