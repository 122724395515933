body {
    background-color: #333333;
}

article {
    display: flex;
    justify-content: center;
    background-color: white;
}

.legend {
    text-align: center;
}

.rgt {
    text-align: right;
}
