article {
    text-align: right;
}

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
}
  
.column {
    flex: 50%;
    padding: 0 4px;
}
  
.column img {
    margin-top: 8px;
    vertical-align: middle;
}
